import React from "react";
import { Route, Switch } from "react-router-dom";
import Auction from "components/Dashboard/Auction/Auction";
import AuctionCreate from "components/Dashboard/Auction/AuctionCreate";
import AuctionUpdate from "components/Dashboard/Auction/AuctionUpdate";
import AuctionDetail from "components/Dashboard/Auction/AuctionDetail";
import Bid from "components/Dashboard/Bid/Bid";
import BidDetail from "components/Dashboard/Bid/BidDetail";
import User from "components/Dashboard/User/User";
import UserCreate from "components/Dashboard/User/UserCreate";

export default () => {
  return (
    <Switch>
      <Route path="/home" exact component={Auction} />
      <Route path="/home/auction/create" component={AuctionCreate} />
      <Route path="/home/auction/:id" exact component={AuctionDetail} />
      <Route path="/home/auction/:id/update" component={AuctionUpdate} />
      <Route path="/home/bid" exact component={Bid} />
      <Route path="/home/bid/:id" component={BidDetail} />
      <Route path="/home/user" exact component={User} />
      <Route path="/home/user/create" exact component={UserCreate} />
    </Switch>
  );
};
