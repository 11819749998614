import React, { Component } from "react";
import { Button, Card, message, Input, Form, Spin } from "antd";
import axios from "services/axios";
import pickObj from "lodash/pick";

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      nomorHp: "",
    }
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      formData.append("fotoKtp", this.state.fotoKtp)
      formData.append("fotoProfil", this.state.fotoProfil)

      let payload =pickObj(this.state, ["name", "nomorHp", "email", "password"]);
      let addUser = await axios.post(`/user/register`, payload);
      message.success("Berhasil menambah User")
      this.props.history.push("/home/user");
    } catch(e) {
      console.log(e)
      message.error(e.message)
    }
  };

  onChangeField = (e, field) => {
      this.setState({
        [field]: e.target.value,
      });
  };

  
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
    };

    return(
      <Card>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Form.Item label="Nama">
            {getFieldDecorator('name', {
              rules: [{
                  required: true,
                  message: 'Nama Wajib diisi',
                }],
                initialValue: this.state.name
            })(<Input onChange={(e) => this.onChangeField(e, "name")} />)}
          </Form.Item>

          <Form.Item label="E-mail">
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'Mohon masukkan email yang valid',
                },
                {
                  required: true,
                  message: 'Email wajib diisi',
                },
              ],
              initialValue: this.state.email
            })(<Input onChange={(e) => this.onChangeField(e, "email")}  />)}
          </Form.Item>

          <Form.Item label="Password">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Password wajib diisid" }],
              initialValue: this.state.password
            })(<Input type="password" onChange={(e) => this.onChangeField(e, "password")}  />)}
          </Form.Item>

          <Form.Item label="No. Handphone">
            {getFieldDecorator("nomorHp", {
              rules: [{ required: true, message: "Nomor Handphone wajib diisi" }],
              initialValue: this.state.nomorHp
            })(<Input onChange={(e) => this.onChangeField(e, "nomorHp")}  />)}
          </Form.Item>

          <Form.Item wrapperCol={{ span: 2, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Add User
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const WrappedRegistrationForm = Form.create({ name: 'create-user' })(CreateUser);
export default WrappedRegistrationForm;
