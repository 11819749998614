import axios from "axios";
import get from "lodash/get";
import { message } from "antd";
import { authLogout } from "store/actions/auth";
import store from "store/configureStore";

store.subscribe(listener);

function select(state) {
  return state.Auth.token;
}

function listener() {
  let token = select(store.getState());
  axios.defaults.headers.common["Authorization"] = token;
}

let instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(reject) {
    let error = get(reject, "response.data.error.text", undefined);
    if (error === "TOKEN_NOT_VALID") {
      message.error("Seseorang telah login dengan user yang sama.");
      store.dispatch(authLogout());
    }
    return Promise.reject(reject);
  },
);

export default instance;
