import React, { Component } from "react";
import axios from "services/axios";
import { connect } from "react-redux";
import moment from "moment";
import {
  Card,
  Form,
  Input,
  Icon,
  Select,
  Row,
  Col,
  Button,
  InputNumber,
  DatePicker,
  Upload,
  message, 
  Spin
} from 'antd';

const { TextArea } = Input;
const { Option } = Select;

class CreateAuction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carTypeId: "",
      validFrom: null,
      validTo: null,
      harga: "",
      lokasi: "",
      warna: "",
      pajak: moment(),
      vinCode: "",
      transmisi: "",
      kapasitasMesin: "",
      bahanBakar: "",
      platNomor: "",
      KM: "",
      description: "",
      countImage: 0,
      images: [],
      carBrand: [],
      carType: [],
      carModel: [],
      confirmDirty: false,
      autoCompleteResult: [],
      uploading: false,
      endOpen: false,
      isUpload: false,
    }
  }

  async componentDidMount () {
    console.log(this.props.Auth)
    let {data} = await axios("/car-brand");
    this.setState({
      carBrand: data.carBrand
    })
  }

  async getCarModels(carBrandId) {
    let {data} = await axios(`/car-brand/${carBrandId}/models`);
    this.setState({
      carModel: data.carModels
    })
  }

  async getCarTypes(carModelId) {
    let {data} = await axios(`/car-model/${carModelId}/types`);
    this.setState({
      carType: data.carTypes
    })
  }



   handleSubmit = (e) => {
      e.preventDefault();
      const {
        carTypeId, 
        validFrom, 
        validTo, 
        vinCode, 
        harga, 
        lokasi, 
        warna, 
        pajak,
        kapasitasMesin,
        bahanBakar,
        transmisi,
        KM,
        platNomor,
        description,
        images
      } = this.state;
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.setState({
            isUpload: true
          })

          let formData = new FormData();
          for(let key in images) {
            formData.append("images", images[key]);
          }
          let payload ={
            carTypeId, 
            validFrom, 
            validTo, 
            vinCode, 
            harga, 
            lokasi, 
            warna, 
            pajak,
            kapasitasMesin,
            bahanBakar,
            transmisi,
            KM,
            platNomor,
            description};

            axios
              .post(`/auction/create`, payload, {headers: {authorization: this.props.Auth.token}})
              .then(({ data }) => {
                if(data.error) {
                  this.setState({
                    isUpload: false
                  }, () => {
                    message.error(data.message)
                  })
                } else if(data.id) {
                  axios
                  .put(`/auction/${data.auction.id}/update`, formData ,{headers: {"Content-type": "multipart/form-data"}})
                  .then(() => {
                    message.success("auction berhasil dibuat")
                    this.props.history.push("/home");
                  })
                } else {
                  message.success("auction berhasil dibuat")
                  this.props.history.push("/home");
                }
              })
              .catch(e => {
                message.error("Terjadi Kesalahan saat request data")
              })
        }
      });
  };

  handleSelectChange = async  (field, value) => {
    switch(field) {
      case "brand" :
        await this.getCarModels(value)
        break;
      case "model" :
        await this.getCarTypes(value)
        break;
      case "tipe" :
        await this.setState({
          carTypeId: value
        })
        break;
      default: 
        await this.setState({
          [field]: value
        })
    }
  };

  disabledStartDate = startValue => {
    const { validTo } = this.state;
    if (!startValue || !validTo) {
      return false;
    }
    return startValue.valueOf() > validTo.valueOf();
  };

  disabledEndDate = endValue => {
    const { validFrom } = this.state;
    if (!endValue || !validFrom) {
      return false;
    }
    return endValue.valueOf() <= validFrom.valueOf();
  };

  onChangeField = (field, e) => {
    // console.log(e)
      this.setState({
        [field]: e.target.value,
      });
  };

  onStartChange = value => {
    this.onChange('validFrom', value);
  };

  onEndChange = value => {
    this.onChange('validTo', value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  
  render() {
    const { getFieldDecorator } = this.props.form;
    let {carBrand, carModel, carType, images, endOpen} = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const props = {
      multiple: true,
      accept: "image/*",
      onRemove: file => {
        this.setState(state => {
          const index = state.images.indexOf(file);
          const newImages = state.images.slice();
          newImages.splice(index, 1);
          return {
            images: newImages,
            countImage: newImages.length,
          };
        });
      },
      beforeUpload: file => {
        console.log(file)
        this.setState(state => ({
          images: [...state.images, file],
          countImage: [...state.images, file].length,
        }));
        return false;
      },
      images,
    };

    return (
      <Card
        style={{
          marginTop: 35,
          background: "#fff",
          minHeight: 360,
        }}
      >
        <Spin size="large" spinning={this.state.isUpload} tip="Loading...">
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Brand Mobil">
                {getFieldDecorator('carBrand', {
                  rules: [{ required: true, message: 'Brand mobil wajib diisi' }],
                })(
                  <Select
                    placeholder="Pilih salah satu Brand mobil yang terdaftar"
                    onChange={(value) => this.handleSelectChange("brand", value)}
                  >
                    {
                      carBrand.length > 0 ? (
                        carBrand.map( (brandItem, index) => {
                          return (
                            <Option value={brandItem.id} key={index}>{brandItem.brand}</Option>
                          )
                        })
                      ) : (
                        <Option value="0" disabled>-- no registered brand --</Option>
                      )
                    }
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label="Model Mobil">
                {getFieldDecorator('carModel', {
                  rules: [{ required: true, message: 'Model mobil wajib diisi' }],
                })(
                  <Select
                    placeholder="Pilih salah satu Model mobil yang terdaftar"
                    onChange={(value) => this.handleSelectChange("model", value)}
                  >
                    {
                      carModel.length > 0 ? (
                        carModel.map( (modelItem, index) => {
                          return (
                            <Option value={modelItem.id} key={index}>{modelItem.carModel}</Option>
                          )
                        })
                      ) : (
                        <Option value="0" disabled>-- Model tidak tersedia, pilih brand mobil dahulu --</Option>
                      )
                    }
                  </Select>,
                )}
              </Form.Item>

              <Form.Item label="Tipe Mobil">
                {getFieldDecorator('carType', {
                  rules: [{ required: true, message: 'Tipe mobil wajib diisi' }],
                })(
                  <Select
                    placeholder="Pilih salah satu Tipe mobil yang terdaftar"
                    onChange={(value) => this.handleSelectChange("tipe", value)}
                  >
                    {
                      carType.length > 0 ? (
                        carType.map( (typeItem, index) => {
                          return (
                            <Option value={typeItem.id} key={index}>{typeItem.carType}</Option>
                          )
                        })
                      ) : (
                        <Option value="0" disabled >-- Tipe tidak tersedia, pilih model mobil dahulu --</Option>
                      )
                    }
                  </Select>,
                )}
              </Form.Item>

              <Form.Item label="Kilometer" {...formItemLayout}>
                {getFieldDecorator("KM", {
                  rules: [{ required: true, message: "Masukkan Kilometer Mobil" }],
                  initialValue: this.state.KM,
                })(
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Masukkan Kilometer Kendaraan"
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/IDR\s?|(,*)/g, "")}
                    onChange={(value) => this.setState({KM: value})}
                  />,
                )}
              </Form.Item>
              
              <Form.Item label="Vin Code" {...formItemLayout}>
                {getFieldDecorator("vinCode", {
                  rules: [{ required: true, message: "Masukkan Vin Code" }],
                  initialValue: this.state.vinCode,
                })(
                  <InputNumber
                    placeholder="Masukkan Vin Code. Contoh: 2018"
                    style={{ width: "100%" }}
                    onChange={(value) => this.setState({vinCode: value})}
                  />,
                )}
              </Form.Item>

              <Form.Item label="Transmisi" {...formItemLayout}>
                {getFieldDecorator("transmisi", {
                  rules: [{ required: true, message: "Masukkan Transmisi Kendaraan" }]
                })(
                  <Select
                  placeholder="Pilih salah satu Tipe mobil yang terdaftar"
                    onChange={(value) => this.handleSelectChange("transmisi", value)}
                  >
                    <Option value="AT">AT</Option>
                    <Option value="MT">MT</Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item label="Kapasitas Mesin" {...formItemLayout}>
                {getFieldDecorator("kapasitasMesin", {
                  rules: [{ required: true, message: "Masukkan Kapasitas Mesin Kendaraan" }],
                  initialValue: this.state.kapasitasMesin,
                })(
                  <InputNumber style={{ width: "100%" }} 
                    placeholder="Kapasitas mesin" 
                    onChange={(value) => this.setState({kapasitasMesin: value})} 
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/IDR\s?|(,*)/g, "")}
                  />,
                )}
              </Form.Item>

              <Form.Item label="Bahan Bakar" {...formItemLayout}>
                {getFieldDecorator("bahanBakar", {
                  rules: [{ required: true, message: "Masukkan Bahan Bakar Kendaraan" }],
                })(
                  <Select
                    placeholder="Pilih salah satu Bahan Bakar"
                    onChange={(value) => this.handleSelectChange("bahanBakar", value)}
                  >
                    <Option value="Bensin">Bensin</Option>
                    <Option value="Solar">Solar</Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item label="Warna Kendaraan" {...formItemLayout}>
                {getFieldDecorator("warna", {
                  rules: [{ required: true, message: "Masukkan Warna Kendaraan" }],
                })(
                  <Select
                    placeholder="Pilih salah satu Warna Kendaraan"
                    onChange={(value) => this.handleSelectChange("warna", value)}
                  >
                    <Option value="Abu-Abu">Abu-Abu</Option>
                    <Option value="Biru">Biru</Option>
                    <Option value="Coklat">Coklat</Option>
                    <Option value="Hijau">Hijau</Option>
                    <Option value="Hitam">Hitam</Option>
                    <Option value="Kuning">Kuning</Option>
                    <Option value="Merah">Merah</Option>
                    <Option value="Oranye">Oranye</Option>
                    <Option value="Putih">Putih</Option>
                    <Option value="Silver">Silver</Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item label="Pajak Kendaraan" {...formItemLayout}>
                {getFieldDecorator("pajak", {
                  rules: [{ required: true, message: "Masukkan Pajak Kendaraan" }],
                  initialValue: this.state.pajak,
                })(
                  <DatePicker
                    setFieldsValue={this.state.pajak}
                    onChange={dateVal => this.setState({ pajak: dateVal })}
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD"
                    placeholder="Masukkan Tahun Pajak Kendaraan"
                  />,
                )}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Plat Nomor Kendaraan" {...formItemLayout}>
                {getFieldDecorator("platNomor", {
                  rules: [{ required: true, message: "Masukkan Plat Nomor Kendaraan" }],
                  initialValue: this.state.platNomor,
                  
                })(
                  <Input onChange={(e) => this.onChangeField("platNomor", e)}/>,
                )}
              </Form.Item>
              
              <Form.Item label="Harga" {...formItemLayout}>
                {getFieldDecorator("harga", {
                  rules: [{ required: true, message: "Masukkan Harga Penawaran" }],
                  initialValue: this.state.harga,
                })(
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={value => `IDR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={value => value.replace(/IDR\s?|(,*)/g, "")}
                    onChange={(value) => this.setState({harga: value})}
                  />,
                )}
              </Form.Item>

              <Form.Item label="Lokasi Kendaraan" {...formItemLayout}>
                {getFieldDecorator("lokasi", {
                  rules: [{ required: true, message: "Masukkan Lokasi Kendaraan" }],
                  initialValue: this.state.lokasi,
                })(
                  <Select
                    placeholder="Pilih salah satu Lokasi Kendaraan"
                    onChange={(value) => this.handleSelectChange("lokasi", value)}
                  >
                    <Option value="Bintaro">Bintaro</Option>
                    <Option value="Kalimalang">Kalimalang</Option>
                    <Option value="Bekasi Timur">Bekasi Timur</Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item label="Deskripsi" {...formItemLayout}>
                {getFieldDecorator("description", {
                  rules: [{ required: true, message: "Masukkan Deskripsi Kendaraan" }],
                  initialValue: this.state.description,
                })(
                  <TextArea
                  placeholder="Deskripsi kendaraan..."
                  autosize={{ minRows: 3, maxRows: 6 }}
                  onChange={(e) => this.onChangeField("description",e)}
                />,
                )}
              </Form.Item>

              <h3>Jangka Waktu Penawaran</h3>

              <Form.Item label="Waktu Awal Penawaran" {...formItemLayout}>
                {getFieldDecorator("validFrom", {
                  rules: [{ required: true, message: "Masukkan Jadwal Penawaran Kendaraan" }],
                  initialValue: this.state.validFrom,
                })(
                  <DatePicker
                    disabledDate={this.disabledStartDate}
                    showTime
                    setFieldsValue={this.state.validFrom}
                    onChange={dateVal => this.setState({ validFrom: dateVal })}
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="Start Offering"
                    onOpenChange={this.handleStartOpenChange}
                  />
                )}
              </Form.Item>

              <Form.Item label="Batas Akhir Penawaran" {...formItemLayout}>
                {getFieldDecorator("validTo", {
                  rules: [{ required: true, message: "Masukkan Jadwal Penawaran Kendaraan" }],
                  initialValue: this.state.validTo,
                })(
                  <DatePicker
                    disabledDate={this.disabledEndDate}
                    showTime
                    setFieldsValue={this.state.validTo}
                    onChange={dateVal => this.setState({ validTo: dateVal })}
                    style={{ width: "100%" }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="End Offering"
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}
                  />,
                )}
              </Form.Item>
              
              <h3> Upload Gambar Mobil</h3>
              <div>
                <Upload {...props}>
                  <Button disabled={this.state.countImage >= 5 }>
                    <Icon type="upload" /> Select File
                  </Button>
                </Upload>
              </div>
            </Col>
          </Row>

          <Form.Item wrapperCol={{ span: 2, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        </Spin>
      </Card>
    );
  }
}

const mapStateToProps = ({ Auth }) => ({Auth})

const WrappedRegistrationForm = Form.create({ name: 'create-auction' })(CreateAuction);
export default connect(mapStateToProps)(WrappedRegistrationForm);
