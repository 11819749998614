import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

export const doAuth = reqBody => async dispatch => {
  dispatch(authOnRequest());

  try {
    const request = await axios.post(`${api_url}/user/login`, reqBody);
    // const stringResult = JSON.stringify(request.data);
    return dispatch(authRequestSuccess(request.data));
  } catch (err) {
    return dispatch(authRequestFailed(err));
  }
};

export const authLocalJWT = loginData => async dispatch => {
  dispatch(authOnRequest());
  try {
    if (loginData.token === undefined) {
      throw new Error("No token in local storage");
    }
    return dispatch(authRequestSuccess(loginData));
  } catch (err) {
    return dispatch(authRequestFailed(err));
  }
};

export const authLogout = () => {
  window.localStorage.clear();
  return {
    type: "AUTH_LOGOUT",
  };
};

export const authOnRequest = () => {
  return {
    type: "AUTH_REQUEST",
  };
};

export const authRequestFailed = err => ({
  type: "AUTH_REQUEST_FAILED",
  payload: err,
});

export const authRequestSuccess = payload => ({
  type: "AUTH_REQUEST_SUCCESS",
  payload,
});
