import React, { Component } from "react";
import { Form, Icon, Input, Button, Row, Col, Card } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { doAuth, authLocalJWT } from "store/actions/auth";
import AlertMessage from "components/Alert/Alert";
import logo from "assets/images/logo-min.png";

import "./login.css";

const FormItem = Form.Item;

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      loginInProgress: false,
      message: "",
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      } else {
        this.setState({
          message: "Email atau Password wajib diisi",
        });
      }
    });
  };

  componentDidMount() {
    let storage = localStorage.getItem("b2bAdmin");
    if (storage) {
      try {
        let userDetail = JSON.parse(storage);
        if (userDetail) {
          if (!userDetail.token) {
            throw new Error("Invalid local data format");
          } else {
            this.props.authLocalJWT(userDetail);
          }
        }
      } catch (err) {
        localStorage.clear();
      }
    } else {
      console.log("tidak ada user detail di localStorage");
    }
  }

  componentDidUpdate(prevProps) {
    const { Auth } = this.props;
    if (prevProps.Auth.isAuthenticated !== Auth.isAuthenticated) {
      if (Auth.user.isAdmin) {
        localStorage.setItem("b2bAdmin", JSON.stringify(Auth));
        this.props.history.push("/home");
      } else {
        this.setState({
          message: "User not authorized",
        });
      }
    }
    if (!prevProps.Auth.error && Auth.error) {
      this.setState({
        message: "Email atau Password Salah",
      });
    }
  }

  onChangeInput = event => {
    this.setState({
      [event.target.name]: event.target.value,
      message: "",
    });
  };

  onLogin = e => {
    e.preventDefault();
    console.log(this.state, "here from state")
    let payload = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.doAuth(payload);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { message } = this.state;

    return (
      <div>
        <Row type="flex" justify="space-around">
          <Col lg={{ span: 6 }} sm={{ span: 10 }}>
            <AlertMessage message={message} />
            <Card
              style={{
                marginTop: 100,
                borderRadius: 10,
                boxShadow: "2px 4px 66px -5px rgba(214,207,214,1)",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={logo}
                  alt="logo_img"
                  style={{ width: 200, margin: "0px auto" }}
                />
              </div>
              <Form onSubmit={this.onLogin} className="login-form">
                <FormItem>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      onChange={this.onChangeInput}
                      name="email"
                      placeholder="Email"
                    />,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      onChange={this.onChangeInput}
                      name="password"
                      type="password"
                      placeholder="Password"
                    />,
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Log in
                  </Button>
                </FormItem>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const WrappedLoginPage = Form.create()(LoginPage);

const mapStateToProps = state => {
  return {
    Auth: state.Auth,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ doAuth, authLocalJWT }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WrappedLoginPage);
