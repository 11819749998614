import React, { Component } from "react";
import { Button, Card, Tooltip, Input, Icon, Modal, Spin, message } from "antd";
import { Link } from "react-router-dom";
import axios from "services/axios";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const confirmDelete = Modal.confirm;

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searcText: "",
      users: [],
      isDataReady: false
    }
  }

  componentDidMount = async() => {
    await this.fetchUser()
  };

  fetchUser = async () => {
    let request = await axios.get("/user");
    this.setState({
      users: request.data.users,
      isDataReady: true
    })
  }

  deleteUser(id) {
    let self = this;
    confirmDelete({
      title: "Anda yakin ingin menghapus user ini ?",
      content: "Jika Anda klik OK, maka data user akan terhapus dari database",
      onOk() {
        axios
          .delete(`user/${id}/delete`)
          .then(response => {
            return axios.get("/user")
          })
          .then(res => {
            self.setState({
              users: res.data.users,
              isDataReady: true
            }, () => {
              self.fetchUser()
              message.success("User berhasil dihapus");
            })
          })
          .catch(err => {
            console.log(err);
            message.error("Gagal menghapus user");
          });
      },
      onCancel() {
        message.warning("Batal menghapus user");
      },
    });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }

    },
    render: text => text,
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getMuiTheme = () => createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          "white-space": "nowrap",
          '&:nth-child(1)': {
            "width": "8em",
            "min-width": "8em",
            "max-width": "8em",
            "word-break": "break-all"
          }
        }
      }
    }
  });

  
  render() {
    const { users } = this.state;
    const columns = [
      "ID", 
      "Nama", 
      "E-mail", 
      "No. Handphone",
      "Action",
    ];

    const options = {
      filter: false,
      responsive: "scrollMaxHeight",
      print: false,
      selectableRows: "none"
    };

    const data = users.length > 0 ? users.map( item => {
      return [
        item.id,
        item.name,
        item.email,
        item.nomorHp,
        <div>
          {/* <Link to={`/home/`}>
            <Tooltip placement="bottom" title="Detail Auction">
              <Button type="primary" icon="bars" />
            </Tooltip>
          </Link>{" "} */}
          <Tooltip placement="bottom" title="Delete User">
            <Button type="danger" icon="delete" onClick={() => this.deleteUser(item.id)} />
          </Tooltip>
        </div>,
      ] 
    }) : []

    return (
      <Card
        style={{
          marginTop: 35,
          background: "#fff",
          minHeight: 360,
        }}
      >
        <Link to={"/home/user/create"}>
          <Button style={{marginBottom: 10}}>Tambah User</Button>
        </Link>
        <Spin size="large" spinning={!this.state.isDataReady} tip="Loading...">
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              title={"USER"}
              data={data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Spin>
      </Card>
    );
  }
}

export default User;
