import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "services/axios";
import moment from "moment";
import { Button, Card, Descriptions, Empty, Col } from "antd";
import Lightbox from 'react-image-lightbox';

class DetailAuction extends Component  {
  constructor(props) {
    super(props);
    this.state= {
      showModalImage: false,
      imageStyle: {backgroundImage: '', backgroundPosition: '0% 0%'},
      imageUrl: "",
    };
  }

  componentDidMount() {
    axios
      .get(`/auction/${this.props.match.params.id}`, {headers:{
        'X-Requested-With': 'XMLHttpRequest'
      }})
      .then( res => {
        console.log(res.data.auction)
        this.setState({
          auction : res.data.auction
        })
      })
  }

  setPhotoModalVisible = (key, imageUrl = {}) => {
    this.setState({
      showModalImage: key,
      imageUrl,
      imageStyle: {...this.state.imageStyle, backgroundImage: `url(${imageUrl})`  }
    });
  }
  

  render () {
    const {auction} = this.state;
    return auction ? (
      <div>
        <Card style={{
          marginTop: 35,
          background: "#fff",
          minHeight: 360,
        }}
        extra={
          <Link to={`/home/auction/${auction.id}/update`}>
            <Button type="primary">Edit</Button>
          </Link>
        }
        >
          <Descriptions title="Detail Penawaran" span={2}>
            <Descriptions.Item label="Brand Kendaraan">{auction.CarType.CarModel.CarBrand.brand}</Descriptions.Item>
            <Descriptions.Item label="Model Kendaraan">{auction.CarType.CarModel.carModel}</Descriptions.Item>
            <Descriptions.Item label="Tipe Kendaraan">{auction.CarType.carType}</Descriptions.Item>
            <Descriptions.Item label="Tahun Kendaraan">{auction.tahunKendaraan}</Descriptions.Item>
            <Descriptions.Item label="Plat Nomor">{auction.platNomor}</Descriptions.Item>
            <Descriptions.Item label="Kilometer">{`${auction.KM}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Descriptions.Item>
            <Descriptions.Item label="Pajak Kendaran">{moment(auction.pajak).format("LL")}</Descriptions.Item>
            <Descriptions.Item label="Transmisi">{auction.transmisi}</Descriptions.Item>
            <Descriptions.Item label="Kapasitas Mesin">{auction.kapasitasMesin}</Descriptions.Item>
            <Descriptions.Item label="Bahan Bakar">{auction.bahanBakar}</Descriptions.Item>
            <Descriptions.Item label="Warna">{auction.warna}</Descriptions.Item>
            <Descriptions.Item label="Lokasi">{auction.lokasi}</Descriptions.Item>
            <Descriptions.Item label="Vincode">{auction.vinCode}</Descriptions.Item>
            <Descriptions.Item label="Valid From">{moment(auction.validFrom).format("LLLL")}</Descriptions.Item>
            <Descriptions.Item label="Valid To">{moment(auction.validTo).format("LLLL")}</Descriptions.Item>
            <Descriptions.Item label="Deskripsi">{auction.description}</Descriptions.Item>
          </Descriptions>

          <Descriptions title="Gambar Mobil">
            {auction.photos? auction.photos.map( (image, index) => (
              <Col key={index} xs={24} md={12}>
                <Card style={{marginBottom: 5}}>
                  <img
                    src={image}
                    width="100%"
                    height="300"
                    alt="img_doc"
                    onClick={() => this.setPhotoModalVisible(true, image)}
                  />
                </Card>
              </Col>
            )) : (<Empty/>)}
          </Descriptions>
        </Card>

        {this.state.showModalImage && (
          <Lightbox
            mainSrc={this.state.imageUrl}
            onCloseRequest={() => this.setPhotoModalVisible(false)}
          />
        )}
      </div>
    ) : (
      <Empty style={{marginTop: 150}} />
    )
  }
}

export default DetailAuction