import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Layout, Menu, Dropdown, Icon, Drawer } from "antd";

import { authLogout } from "store/actions/auth";
import logo from "../../assets/images/logo-min.png";
import Routes from "./routes";
import "./home-style.css";

const { Header, Content, Footer, Sider } = Layout;

class Home extends Component {
  state = {
    collapsed: false,
    windowWidth: false,
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  collapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  onLogout = () => {
    this.props.authLogout();
    this.props.history.push("/");
  };

  componentDidMount() {
    let width = window.innerWidth;

    if (width < 768) {
      this.setState({
        windowWidth: true,
      });
    }
  }

  handleMenu = e => {
    switch (e.key) {
      case "home":
        this.props.history.push("/home/");
        break;
      case "bid":
        this.props.history.push("/home/bid");
        break;
      case "user":
        this.props.history.push("/home/user");
        break;
      default:
        console.error("INVALID MENU KEY");
        this.props.history.push("/home");
        break;
    }
  };

  render() {
    const { Auth } = this.props;
    const accountMenu = (
      <Menu>
        <Menu.Item key="0">
          <Icon type="setting" />
          <span>Setting</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1">
          <Icon type="logout" />
          <span>
            <a onClick={this.onLogout}>Logout</a>
          </span>
        </Menu.Item>
      </Menu>
    );
    return (
      <Layout style={{ minHeight: "100vh" }}>
        {this.state.windowWidth ? (
          <Drawer
            visible={!this.state.collapsed}
            placement="left"
            onClose={this.collapse}
            style={{
              padding: 0,
              margin: 0,
              backgroundColor: "transparent",
            }}
            closable={false}
            width={200}
          >
            <Sider
              collapsible
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}
              collapsedWidth="0"
              trigger={null}
              breakpoint="md"
              style={{ minHeight: "100vh", position: "relative", width: "100%", zIndex: 10 }}
            >
              <div
                style={{
                  background: "white",
                  margin: 16,
                  textAlign: "center",
                }}
              >
                <img src={logo} width={this.state.collapsed ? 30 : 80} alt="img_logo" />
              </div>
              <Menu
                theme="dark"
                mode="inline"
                onClick={this.handleMenu}
              >
                <Menu.Item key="home">
                  <Icon type="directions-car" />
                  <span>Live Stock</span>
                </Menu.Item>
                <Menu.Item key="bid">
                  <Icon type="snippets" />
                  <span>Bid</span>
                </Menu.Item>
                <Menu.Item key="user">
                  <Icon type="person" />
                  <span>User</span>
                </Menu.Item>
              </Menu>
            </Sider>
          </Drawer>
        ) : (
          <Sider
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
            collapsedWidth="0"
            trigger={null}
            breakpoint="md"
          >
            <div
              style={{
                background: "white",
                margin: 16,
                textAlign: "center",
              }}
            >
              <img src={logo} width={this.state.collapsed ? 30 : 80} alt="img_logo" />
            </div>
            <Menu
              theme="dark"
              mode="inline"
              onClick={this.handleMenu}
            >
              <Menu.Item key="home">
                <Icon type="car" />
                <span>Live Stock</span>
              </Menu.Item>
              <Menu.Item key="bid">
                <Icon type="snippets" />
                <span>Bid</span>
              </Menu.Item>
              <Menu.Item key="user">
                <Icon type="team" />
                <span>User</span>
              </Menu.Item>
            </Menu>
          </Sider>
        )}

        <Layout>
          <Header style={{ background: "#fff", padding: 0 }}>
            <div className="headercontainer">
              <Icon
                className="trigger"
                type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                onClick={this.collapse}
              />
              <div
                style={{
                  paddingLeft: 24,
                  paddingRight: 24,
                  marginRight: 16,
                  textAlign: "right",
                }}
              >
                <Dropdown overlay={accountMenu} trigger={["click"]}>
                  <a className="ant-dropdown-link">
                    <Icon type="user" /> {Auth.user.email} <Icon type="down" />
                  </a>
                </Dropdown>
              </div>
            </div>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Routes />
          </Content>
          <Footer style={{ textAlign: "center" }}>Toyota Trust ©2019</Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ authLogout }, dispatch);

const mapStateToProps = ({ Auth }) => ({ Auth });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
