import React, { Component } from "react";
import { Alert } from "antd";

class AlertComponent extends Component {
  render() {
    const { message } = this.props;
    if (message !== "") {
      return <Alert message={message} type="error" showIcon />;
    } else {
      return <div />;
    }
  }
}

export default AlertComponent;
