import React, { Component } from "react";
import { Button, Card, message, Tooltip, Input, Icon, Modal, Spin, Tag, Descriptions, Col, Row } from "antd";
import { Link } from "react-router-dom";
import axios from "services/axios";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import moment from "moment";

const confirmBid = Modal.confirm;
class DetailAuction extends Component  {
  constructor(props) {
    super(props);
    this.state= {
      searchText: "",
      isDataReady: false,
      bids: []
    };
  }

  componentDidMount = () => {
    this.fetchBid()
  };

  fetchBid = async () => {
    let request = await axios.get(`/bid/${this.props.match.params.id}/bid-list`);
    this.setState({
      bids: request.data.bids,
      isDataReady: true
    })
  }

  approveBid = async (id, approve) => {
    try {
      let req = await axios.put(`/bid/${id}/approve`, {approve})
      message.success("Update Bid tersimpan")
      this.fetchBid()
    } catch (e) {
      console.log(e)
      message.error(" Terjadi Kesalahan saat request Data")
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }

    },
    render: text => text,
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getMuiTheme = () => createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          "white-space": "nowrap",
          '&:nth-child(1)': {
            "width": "8em",
            "min-width": "8em",
            "max-width": "8em",
            "word-break": "break-all"
          }
        }
      }
    }
  });
  
  render () {
    const { bids } = this.state;
    const columns = [
      "Bid Price",
      "Bidder",
      "Bid Time",
      "Action"
    ];

    const options = {
      filter: false,
      print: false,
      selectableRows: "none",
      responsive: "scrollMaxHeight"
    };

    const data = bids.length > 0 ? bids.map( item => {
      return [
        `IDR ${item.harga}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        item.User.name,
        item.createdAt,
        <div>
          {item.status === null ? (
            <React.Fragment>
              <Tooltip placement="bottom" title="Approve Bid">
                <Button type="primary" onClick={() =>this.approveBid(item.id, true)}>Approve</Button>
              </Tooltip>{" "}

              <Tooltip placement="bottom" title="Reject Bid">
                <Button type="danger" onClick={() => this.approveBid(item.id, false)}>Reject</Button>
              </Tooltip>
            </React.Fragment>
          ) : (
            <Tag color={item.status === "APPROVE" ? "blue" : "red"}>{item.status}</Tag>
          )}
          
        </div>
      ] 
    }) : []

    return (
      <Card
        style={{
          marginTop: 35,
          background: "#fff",
          minHeight: 360,
        }}
      >
        <Spin size="large" spinning={!this.state.isDataReady} tip="Loading...">
          <Row>
            {bids.length !== 0 ? (
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Descriptions title="Auction Info" bordered size="small" style={{marginBottom: 20}}> 
                  <Descriptions.Item label="Brand" span={3}>
                    {bids[0].Auction.CarType.CarModel.CarBrand.brand} {bids[0].Auction.CarType.CarModel.carModel}{bids[0].Auction.CarType.carType}
                  </Descriptions.Item>
                  <Descriptions.Item label="License Plate" span={3}>
                    {bids[0].Auction.platNomor}
                  </Descriptions.Item>
                  <Descriptions.Item label="Price" span={3}>
                    {bids[0].Auction.platNomor}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            ) : null}
          </Row>

          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              title={"BID STOCK"}
              data={data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Spin>
      </Card>
    );
  }
}

export default DetailAuction