import React, { Component } from "react";
import { Button, Card, message, Tooltip, Input, Icon, Modal, Spin } from "antd";
import { Link } from "react-router-dom";
import axios from "services/axios";
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import moment from "moment";

const confirmDelete = Modal.confirm;

class Auction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searcText: "",
      auctions: [],
      isDataReady: false
    }
  }

  componentDidMount = () => {
    this.fetchAuction()
  };

  deleteAuction(id) {
    let self = this;
    confirmDelete({
      title: "Anda yakin ingin menghapus penawaran ini ?",
      content: "Jika Anda klik OK, maka penawaran ini akan dibatalkan",
      onOk() {
        axios
          .delete(`auction/${id}/delete`, {
            headers: {
              authorization: self.props.Auth.token,
            },
          })
          .then(response => {
            message.success("Penawaran berhasil dihapus");
            self.fetchAuction();
          })
          .catch(err => {
            console.log(err);
            message.error("Gagal menghapus penawaran");
          });
      },
      onCancel() {
        message.warning("Batal menghapus penawaran");
      },
    });
  }

  fetchAuction = async () => {
    let request = await axios.get("/auction");
    this.setState({
      auctions: request.data.auctions,
      isDataReady: true
    })
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }

    },
    render: text => text,
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getMuiTheme = () => createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          "white-space": "nowrap",
          '&:nth-child(1)': {
            "width": "8em",
            "min-width": "8em",
            "max-width": "8em",
            "word-break": "break-all"
          }
        }
      }
    }
  });

  
  render() {
    const { auctions } = this.state;
    const columns = [
      "ID", 
      "Title", 
      "License Plate", 
      "Price",
      {
        name: "Valid From",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span id={tableMeta.columnIndex}>{moment(value).format("llll")}</span>
            );
          }
        }
      },
      {
        name: "Valid To",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span id={tableMeta.columnIndex}>{moment(value).format("llll")}</span>
            );
          }
        }
      },
     "Action"
    ];

    const options = {
      filter: false,
      print: false,
      selectableRows: "none",
      responsive: "scrollMaxHeight"
    };

    const data = auctions.length > 0 ? auctions.map( item => {
      return [
        item.id,
        `${item.CarType.CarModel.CarBrand.brand} ${item.CarType.CarModel.carModel} ${item.CarType.carType}`,
        item.platNomor,
        `IDR ${item.harga}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        item.validFrom,
        item.validTo,
        <div>
            <Link to={`/home/auction/${item.id}`}>
              <Tooltip placement="bottom" title="Detail Auction">
                <Button type="primary" icon="bars" />
              </Tooltip>
            </Link>{" "}
            <Tooltip placement="bottom" title="Delete Appraisal">
              <Button type="danger" icon="delete" onClick={() => this.deleteAuction(item.id)} />
            </Tooltip>
          </div>
      ] 
    }) : []

    return (
      <Card
        style={{
          marginTop: 35,
          background: "#fff",
          minHeight: 360,
        }}
      >
        <Link to={"home/auction/create"}>
          <Button type="primary" style={{marginBottom: 10}}>Tambah Stok</Button>
        </Link>

        <Spin size="large" spinning={!this.state.isDataReady} tip="Loading...">
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              title={"LIVE STOCK"}
              data={data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Spin>
      </Card>
    );
  }
}

const mapStateToProps = ({ Auth }) => ({Auth})
export default connect(mapStateToProps)(Auction);
