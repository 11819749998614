import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Dashboard from "./containers/Dashboard";
import LoginPage from "./containers/Login";

const RouteWithProps = ({ path, exact, strict, component: Component, location, auth, ...rest }) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    location={location}
    render={props =>
      auth ? (
        <Component {...rest} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const routes = ({ Auth }) => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <RouteWithProps auth={Auth.isAuthenticated} path="/home" component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

const mapStateToProps = ({ Auth }) => ({ Auth });

export default connect(mapStateToProps)(routes);
